import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout/Layout';
import SEO from '../components/seo/SEO';

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Oops! 404</h1>
    <h3>You just hit a route that does not exist.</h3>
    <Link to="/">Go Home</Link>
  </Layout>
);

export default NotFoundPage;
